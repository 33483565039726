const webURL = 'https://test.tenant.reposit.co.uk';
const clientId = 'CvG6gXjhMb4cUYUykoC2jp7hqh7iTvyv';
const paymentPlanPortalURL = 'https://test.tenant-portal.reposit.co.uk';

export const appConfig = {
  links: {
    auth0: {
      logout: `https://reposit-test.eu.auth0.com/v2/logout?client_id=${clientId}`,
      login: `https://reposit-test.eu.auth0.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${webURL}/authenticate&audience=https://test.api.com`,
    },
    local: {
      login: `${webURL}/login`,
    },
    paymentPlanPortal: paymentPlanPortalURL,
  },
  api: {
    basePath: 'https://test.api.reposit.co.uk',
  },
  analytics: {
    trackers: [
      {
        name: 'test',
        id: 'UA-81451405-6',
      },
    ],
  },
  stripe: {
    apiKey: 'pk_test_J0MDw0x6QrWhf4pLdKcVziuQ',
  },
  hubspot: {
    accountId: '9159844',
    enabled: false,
  },
  intercom: {
    appId: 's7plajks',
    enabled: true,
  },
  hotjar: {
    enabled: false,
    siteId: 0,
    hotjarVersion: 6,
  },
  trustPilot: {
    repositOfferTrustBoxEnabled: true,
  },
  inMaintenanceMode: false,
};
